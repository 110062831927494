import { Component, OnInit, Output, EventEmitter,Input } from '@angular/core';

@Component({
  selector: 'button-edit',
  templateUrl: './button-edit.component.html',
  styleUrls: ['./button-edit.component.scss']
})
export class ButtonEditComponent implements OnInit {
  @Output() eventClick = new EventEmitter<any>();
  @Input() class: string = "btn btn-secondary";

  constructor() { 
    //This is intentional.
  }

  ngOnInit(): void {
    //This is intentional.
  }

  edit() {
    this.eventClick.emit();
  }
}
