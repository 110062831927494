import { NgModule, APP_INITIALIZER } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonSaveComponent } from './button-save/button-save.component';
import { ButtonSaveBlueComponent } from './button-save-blue/button-save-blue.component';
import { TranslatePipe } from '../pipes/translate-pipe';
import { TranslateService } from '@ngx-translate/core';
import { AppPipesModule } from '../pipes/pipes.module';
import { NgPrimeModule } from '../modules/ngprime.module';
import { ButtonCancelComponent } from './button-cancel/button-cancel.component';
import { ButtonCancelRequestComponent } from './button-cancel-request/button-cancel-request.component';
import { ButtonAddComponent } from './button-add/button-add.component';
import { ButtonSearchComponent } from './button-search/button-search.component';
import { ButtonSendApproveComponent } from './button-send-approve/button-send-approve.component';
import { ButtonValidateComponent } from './button-validate/button-validate.component';
import { ButtonDownloadsViewsComponent } from './button-download-view/button-download-view.component';
import { ButtonEditComponent } from './button-edit/button-edit.component';
import { ButtonOpenComponent } from './button-open/button-open.component';
import { ButtonViewComponent } from './button-view/button-view.component';
import { ButtonEditWarningComponent } from './button-edit-warning/button-edit-warning.component';
import { ButtonDisableComponent } from './button-disable/button-disable.component';
import { JabilSelectComponent } from './jabil-select/jabil-select.component';
import { JabilInputComponent } from './jabil-input/jabil-input.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { JabilCheckBoxComponent } from './jabil-check-box/jabil-check-box.component';
import { JabilTextAreaComponent } from './jabil-text-area/jabil-text-area.component';
import { FormsModule } from '@angular/forms';
import { ButtonExportComponent } from './button-export/button-export.component';
import { ButtonRequestDetail } from './button-request-details/button-request-details.component';
import { ButtonApprovers } from './button-approvers/button-approvers.component';
import { ButtonApproveComponent } from './button-approve/button-approve.component';
import { ButtonRejectComponent } from './button-reject/button-reject.component';
import { LinkActionPlanComponent } from './link-action-plan/link-action-plan.component';


export function setupTranslateFactory(
  service: TranslateService): Function {
  return () => service.use('en');
}

@NgModule({
  declarations: [
    ButtonSaveComponent,
    ButtonSaveBlueComponent,
    ButtonCancelComponent,
    ButtonCancelRequestComponent,
    ButtonAddComponent,
    ButtonSearchComponent,
    ButtonSendApproveComponent,
    ButtonValidateComponent,
    ButtonDownloadsViewsComponent,
    ButtonEditComponent,
    ButtonOpenComponent,
    ButtonViewComponent,
    ButtonEditWarningComponent,
    ButtonDisableComponent,
    ButtonExportComponent,
    JabilSelectComponent,
    JabilInputComponent,
    JabilCheckBoxComponent,
    JabilTextAreaComponent,
    ButtonRequestDetail,
    ButtonApprovers,
    ButtonApproveComponent,
    ButtonRejectComponent,
    LinkActionPlanComponent
  ],
  imports: [
    CommonModule,
    AppPipesModule,
    NgSelectModule,
    FormsModule,
    NgPrimeModule
  ],
  exports: [
    ButtonAddComponent,
    ButtonSaveBlueComponent,
    ButtonSearchComponent,
    ButtonSendApproveComponent,
    ButtonEditComponent,
    ButtonOpenComponent,
    ButtonViewComponent,
    ButtonEditWarningComponent,
    ButtonValidateComponent,
    ButtonDownloadsViewsComponent,
    ButtonDisableComponent,
    ButtonSaveComponent,
    ButtonCancelComponent,
    ButtonCancelRequestComponent,
    ButtonExportComponent,
    JabilSelectComponent,
    JabilInputComponent,
    JabilCheckBoxComponent,
    JabilTextAreaComponent,
    ButtonRequestDetail,
    ButtonApprovers,
    ButtonApproveComponent,
    ButtonRejectComponent,
    LinkActionPlanComponent
  ],
  providers: [
    TranslateService,
    {
      provide: APP_INITIALIZER,
      useFactory: setupTranslateFactory,
      deps: [TranslateService],
      multi: true
    }
  ],
})
export class ControlModule { }
