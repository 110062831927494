import { Injectable } from '@angular/core';
import { RestCallService } from '../../../../core/services/rest-call/rest-call.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { NotificationService } from 'src/app/core/services/notification/notification.service';
import { ISites } from '../Interfaces/ISites';
import { TranslateApplicationService } from '../../../../core/translate/translate-application-service';
import { CognitoService } from 'src/app/core/services/cognito/cognito.service';

@Injectable({
  providedIn: 'root'
})
export class SitesService extends RestCallService {
  public dataToSend : ISites = {
                                  pksite:0, 
                                  fksite:0, 
                                  fksitetotimezone:0, 
                                  code : "", 
                                  site : "", 
                                  sitename : "", 
                                  active : false, 
                                  creationdate : new Date(), 
                                  strcreationdate : "", 
                                  fkemployeecreator: 1, 
                                  lastupdated: new Date(), 
                                  strlastupdated: "", 
                                  fkemployeeupdater:1,
                                  fkrecordstatus:1,
                                  employeecreatorname: "",
                                  employeeupdatername: "",
                                  username: "",
                                  recordstatusname:"",
                                  fkemployee:0,
                                  check: false
                                };
 
  constructor(
    public httpClient: HttpClient
    , private cognito: CognitoService
    , public toastr: ToastrService
    , public notificationService: NotificationService
    , public _http: HttpClient
    , public translate: TranslateApplicationService) {
    super(httpClient, 'OTRapi', toastr, notificationService, translate);
  }

  public getList() {
    return this.get<ISites[]>('/api/Sites/GetSites');
  }

  public getSiteNameByPKSite() {
    this.dataToSend.pksite = Number(localStorage.getItem('fksite'));
    return this.post<ISites>('/api/Sites/GetSiteNameByPKSite', this.dataToSend);
  }

  public getSitesByUsername() {
    if (!this.cognito.IsAuhtenticated())
      this.cognito.logOut();
    this.dataToSend.username = localStorage.getItem('username');
    return this.post<ISites[]>('/api/Sites/GetSitesByUsername', this.dataToSend);
  }

  public createSite(data: any) {
    if (!this.cognito.IsAuhtenticated())
			this.cognito.logOut();
    this.dataToSend.code = data.code.trim();
    this.dataToSend.site = data.site.trim();
    this.dataToSend.fksitetotimezone = Number(localStorage.getItem('fksite'));
    this.dataToSend.username = localStorage.getItem('username');
    return this.post('/api/Sites/CreateSite', this.dataToSend);
  }

  public updateSite(data: any) {
    if (!this.cognito.IsAuhtenticated())
			this.cognito.logOut();
    this.dataToSend.pksite = data.pksite;
    this.dataToSend.code = data.code;
    this.dataToSend.site = data.site;
    this.dataToSend.fksitetotimezone = Number(localStorage.getItem('fksite'));
    this.dataToSend.username = localStorage.getItem('username');
    return this.put('/api/Sites/UpdateSite', this.dataToSend);
  }
  
}
