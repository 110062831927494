import { Component, OnInit, Output, EventEmitter,Input } from '@angular/core';

@Component({
  selector: 'button-view',
  templateUrl: './button-view.component.html',
  styleUrls: ['./button-view.component.scss']
})
export class ButtonViewComponent implements OnInit {
  @Output() eventClick = new EventEmitter<any>();
  @Input() class: string = "btn btn-secondary";

  constructor() { 
    //This is intentional.
  }

  ngOnInit(): void {
    //This is intentional.
  }

  view() {
    this.eventClick.emit();
  }
}
