import { Component, OnInit, Output,Input, EventEmitter } from '@angular/core';

@Component({
  selector: 'link-action-plan',
  templateUrl: './link-action-plan.component.html',
  styleUrls: ['./link-action-plan.component.scss']
})
export class LinkActionPlanComponent implements OnInit {
  @Output() eventClick = new EventEmitter<any>();
  @Input() caption: string;

  constructor() { 
    //This is intentional.
  }

  ngOnInit(): void {
    //This is intentional.
  }

  add() {
    this.eventClick.emit();
  }
}
