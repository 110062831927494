import { Component, OnInit, Input, forwardRef, KeyValueDiffers, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'jabil-select',
  templateUrl: './jabil-select.component.html',
  styleUrls: ['./jabil-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => JabilSelectComponent),
      multi: true
    }
  ]
})
export class JabilSelectComponent implements OnInit, ControlValueAccessor {
  @Input() dataSource: any[];
  @Input() displayText: string;
  @Input() displayValue: string;
  @Input() isMultiple: boolean = false;
  @Input() isClearable: boolean = true;
  @Input() caption: string;
  @Input() nameControl: string;
  @Input() textInstruction: string;
  @Output() changeValue: EventEmitter<any> = new EventEmitter();
  selectedValue: any;
  value: any;
  isDisabled: boolean;
  @Input() isReadOnly: boolean = false;
  onChange = (_: any) => { }
  onTouch = () => { }

  constructor() { 
    //This is intentional.
  }

  ngOnInit(): void {
    //This is intentional.
  }


  onSelected(value: any) {
    this.value = value;
    this.onTouch();
    this.onChange(this.value);
    this.changeValue.emit(this.value);
  }

  writeValue(value: any): void {
    if (value != null) {
      this.value = value || '';
      this.changeValue.emit(this.value);
    }
  }

  registerOnChange(fn: any): void { this.onChange = fn; }
  registerOnTouched(fn: any): void { this.onTouch = fn; }
  setDisabledState(isDisabled: boolean): void { this.isDisabled = isDisabled; }

}
