import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'button-cancel-request',
  templateUrl: './button-cancel-request.component.html',
  styleUrls: ['./button-cancel-request.component.scss']
})
export class ButtonCancelRequestComponent implements OnInit {
  @Output() eventClick = new EventEmitter<any>();

  constructor() { 
    //This is intentional.
  }

  ngOnInit(): void {
    //This is intentional.
  }
  
  cancelRequest() {
    this.eventClick.emit();
  }
}
