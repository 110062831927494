import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'button-save-blue',
  templateUrl: './button-save-blue.component.html',
  styleUrls: ['./button-save-blue.component.scss']
})
export class ButtonSaveBlueComponent implements OnInit {
  @Output() eventClick = new EventEmitter<any>();

  constructor() { 
    //This is intentional.
  }

  ngOnInit(): void {
    //This is intentional.
  }

  saveBlue() {
    this.eventClick.emit();
  }

}
