import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'button-send-approve',
  templateUrl: './button-send-approve.component.html',
  styleUrls: ['./button-send-approve.component.scss']
})
export class ButtonSendApproveComponent implements OnInit {
  @Output() eventClick = new EventEmitter<any>();

  constructor() { 
    //This is intentional.
  }

  ngOnInit(): void {
    //This is intentional.
  }

  sendApprove() {
    this.eventClick.emit();
  }

}
