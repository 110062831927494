import { Injectable } from '@angular/core';
import { RestCallService } from '../../../../core/services/rest-call/rest-call.service';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { NotificationService } from 'src/app/core/services/notification/notification.service';
import { IEmployees } from '../Interfaces/IEmployees';
//import { IRoles } from '../Interfaces/IRoles';
//import { ActiveDirectoryService } from '../services/activedirectory-services';
import { TranslateApplicationService } from '../../../../core/translate/translate-application-service';
import { CognitoService } from 'src/app/core/services/cognito/cognito.service';
import { IPositions } from '../Interfaces/IPositions';

@Injectable({
  providedIn: 'root'
})
export class EmployeesService extends RestCallService {
  public dataToSend : IEmployees = {
    fkemployee: 0,
    workdayid: "",
    username: "",
    employeename: "",
    positioncode: "",
    employeecategory: "",
    position: "",
    email: "",
    phone:"",
    costcentercode:"",
    shiftcode:"",
    creationdate: new Date,
    strcreationdate: "",
    fkemployeecreator: 0,
    lastupdated: new Date,
    strlastupdated: "",
    fkemployeeupdater: 0,
    fkrecordstatus: 0,
    employeecreatorname: "",
    employeeupdatername: "",
    recordstatusname: "",
    usernameupdater: "",

    fkcostcenter: 0,
    fkshift: 0,

    fkrole: 0,
    rolename: "",
    fksite: 0,
    fksitetotimezone: 0,
    sitename: "",
  };

  constructor(
    public httpClient: HttpClient,
    private cognito: CognitoService,
    public toastr: ToastrService,
    public notificationService: NotificationService,
    //public activeDirectoryService: ActiveDirectoryService,
    public translate: TranslateApplicationService
  ) {
    super(httpClient, 'OTRapi', toastr, notificationService, translate);
  }

  public getList(fksite:number) {
    if (!this.cognito.IsAuhtenticated())
			this.cognito.logOut();
    this.dataToSend.fksite = fksite;
    return this.post<IEmployees[]>('/api/Employees/GetEmployees', this.dataToSend);
  }

  public getEmployeeByUserName(userName: string) {
    if (!this.cognito.IsAuhtenticated())
			this.cognito.logOut();
    this.dataToSend.username = userName;
    return this.post<IEmployees>('/api/Employees/GetEmployeeByUserName', this.dataToSend);
  }

  public createEmployee(data: any) {
    if (!this.cognito.IsAuhtenticated())
			this.cognito.logOut();
    this.dataToSend.workdayid = data.workdayid;
    this.dataToSend.username = data.username;
    this.dataToSend.employeename = data.employeename;
    this.dataToSend.position = data.position == null ? '':data.position;
    this.dataToSend.email = data.email == null ? '':data.email;
    this.dataToSend.phone = data.phone == null ? '':data.phone;
    this.dataToSend.costcentercode = data.costcentercode == null ? '':data.costcentercode;
    this.dataToSend.shiftcode = data.shiftcode == null ? '':data.shiftcode;
    this.dataToSend.fkcostcenter = data.fkcostcenter == null ? 0:data.fkcostcenter;
    this.dataToSend.fkshift = data.fkshift == null ? 0:data.fkshift;
    this.dataToSend.fksite = data.fksite == null ? 0:data.fksite;
    this.dataToSend.fkrole = data.fkrole == null ? 0:data.fkrole;
    this.dataToSend.rolename = data.rolename == null ? "":data.rolename;
    this.dataToSend.fksitetotimezone = Number(localStorage.getItem('fksite'));
    this.dataToSend.usernameupdater = localStorage.getItem('username');
    return this.post('/api/Employees/CreateEmployee', this.dataToSend);
  }

  public verifyEmailInEmployee(data: any) {
    if (!this.cognito.IsAuhtenticated())
			this.cognito.logOut();
    this.dataToSend.username = data.username;
    return this.post<IEmployees>('/api/Employees/VerifyEmailInEmployee', this.dataToSend);
  }

  public verifyPositionCode(data: any) {
    if (!this.cognito.IsAuhtenticated())
			this.cognito.logOut();
    this.dataToSend.positioncode = data.positioncode;
    this.dataToSend.position = data.position;
    this.dataToSend.fksite = data.fksite;
    return this.post<IPositions[]>('/api/Employees/VerifyPositionCode', this.dataToSend);
  }

  public verifyCostCenter(data: any) {
    if (!this.cognito.IsAuhtenticated())
			this.cognito.logOut();
    this.dataToSend.fkcostcenter = data.fkcostcenter;
    this.dataToSend.costcentercode = data.costcentercode;
    this.dataToSend.workdayid = data.workdayid;
    this.dataToSend.fksite = data.fksite;
    return this.post<any[]>('/api/Employees/VerifyCostCenter', this.dataToSend);
  }

  public setEmployeeRole(data: any) {
    if (!this.cognito.IsAuhtenticated())
			this.cognito.logOut();
    this.dataToSend.fkemployee = data.fkemployee;
    this.dataToSend.username = data.username;
    this.dataToSend.fkrole = data.fkrole;
    this.dataToSend.usernameupdater = localStorage.getItem('username');
    return this.post('/api/Employees/SetEmployeeRole', this.dataToSend);
  }

  public setEmployeeSite(data: any, fksite:number) {
    if (!this.cognito.IsAuhtenticated())
			this.cognito.logOut();
    this.dataToSend.fkemployee = data.fkemployee;
    this.dataToSend.username = data.username;
    this.dataToSend.fksite = fksite;
    this.dataToSend.usernameupdater = localStorage.getItem('username');
    return this.post('/api/Employees/SetEmployeeSite', this.dataToSend);
  }

  public UpdateEmployeeRolesAndSites(data: any) {
    if (!this.cognito.IsAuhtenticated())
			this.cognito.logOut();
    this.dataToSend.fkemployee = data.fkemployee;
    this.dataToSend.fkrole = data.fkrole;
    this.dataToSend.usernameupdater = localStorage.getItem('username');
    return this.put('/api/Employees/UpdateEmployeeRolesAndSites', this.dataToSend);
  }

  public disableEmployeeRoles(data: any) {
    if (!this.cognito.IsAuhtenticated())
			this.cognito.logOut();
    this.dataToSend.fkemployee = data.fkemployee;
    this.dataToSend.fkrole = data.fkrole;
    this.dataToSend.usernameupdater = localStorage.getItem('username');
    return this.put('/api/Employees/DisableEmployeeRoles', this.dataToSend);
  }

  public disableEmployee(data: any) {
    if (!this.cognito.IsAuhtenticated())
			this.cognito.logOut();
    this.dataToSend.fkemployee = data.fkemployee;
    this.dataToSend.usernameupdater = localStorage.getItem('username');
    return this.put('/api/Employees/DisableEmployee', this.dataToSend);
  }
  public availableBuilding(data: any) {
    if (!this.cognito.IsAuhtenticated())
			this.cognito.logOut();
    this.dataToSend.fkemployee = data.fkemployee;
    this.dataToSend.usernameupdater = localStorage.getItem('username');
    return this.put('/api/Employees/AvailableEmployee', this.dataToSend);
  }
}
