import { Injectable } from '@angular/core';
import { RestCallService } from '../../../../core/services/rest-call/rest-call.service';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { NotificationService } from 'src/app/core/services/notification/notification.service';
import { IProperties } from '../Interfaces/IProperties';
import { TranslateApplicationService } from '../../../../core/translate/translate-application-service';
import { CognitoService } from 'src/app/core/services/cognito/cognito.service';
import { ILastReleases } from '../Interfaces/ILastReleases';
import { IEnvironments } from '../Interfaces/IEnvironments';

@Injectable({
  providedIn: 'root'
})
export class PropertiesService extends RestCallService {
  public dataToSend : IProperties = {
                                  pkproperty:0, 
                                  property : "", 
                                  propertyvalue : "", 
                                  description : "", 
                                  creationdate : new Date(), 
                                  strcreationdate : "", 
                                  fkemployeecreator: 1, 
                                  lastupdated: new Date(), 
                                  strlastupdated: "", 
                                  fkemployeeupdater:1,
                                  fkrecordstatus:1,
                                  employeecreatorname: "",
                                  employeeupdatername: "",
                                  username: "",
                                  recordstatusname:""
                                };

 
  constructor(
    public httpClient: HttpClient
    , private cognito: CognitoService
    , public toastr: ToastrService
    , public notificationService: NotificationService
    , public translate: TranslateApplicationService) {
    super(httpClient, 'OTRapi', toastr, notificationService, translate);
  }

  public getList() {
    if (!this.cognito.IsAuhtenticated())
			this.cognito.logOut();
    return this.get<IProperties[]>('/api/Properties/GetProperties');
  }

  public getLastReleases() {
    if (!this.cognito.IsAuhtenticated())
			this.cognito.logOut();
    return this.get<ILastReleases[]>('/api/Properties/GetLastReleases');
  }

  public getPropertyByName(data: string) {
    this.dataToSend.property = data;
    return this.post<IProperties[]>('/api/Properties/GetPropertyByName', this.dataToSend);
  }
  public getActiveEnvironment() {
    if (!this.cognito.IsAuhtenticated())
			this.cognito.logOut();
    return this.get<IEnvironments[]>('/api/Properties/GetActiveEnvironment');
  }
}
