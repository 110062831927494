import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'button-validate',
  templateUrl: './button-validate.component.html',
  styleUrls: ['./button-validate.component.scss']
})
export class ButtonValidateComponent implements OnInit {
  @Output() eventClick = new EventEmitter<any>();

  constructor() { 
    //This is intentional.
  }

  ngOnInit(): void {
    //This is intentional.
  }

  validate() {
    this.eventClick.emit();
  }
}
