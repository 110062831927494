import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'button-download-view',
  templateUrl: './button-download-view.component.html',
  styleUrls: ['./button-download-view.component.scss']
})
export class ButtonDownloadsViewsComponent implements OnInit {
  @Output() eventClick = new EventEmitter<any>();

  constructor() { 
    //This is intentional.
  }

  ngOnInit(): void {
    //This is intentional
  }

  downloadViews() {
    this.eventClick.emit();
  }
}
