import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { PropertiesService } from '../content/sections/services/properties-services';
import { TransactionResult } from 'src/app/core/services/interfaces/transaction-result';
import { IProperties } from '../content/sections/Interfaces/IProperties';
import { IEnvironments } from '../content/sections/Interfaces/IEnvironments';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  version: string = "";
  public environment: string;
  constructor(
    private propertyService: PropertiesService,
  ) { 
    //this.production = environment.production;
    //this.version = environment.version;
  }

  ngOnInit() {
    this.propertyService.getPropertyByName("APP_VERSION").subscribe( 
      (result: TransactionResult<IProperties[]>) => {
        if (result.success) {
          this.version = result.data[0].propertyvalue;
        }
      },
      (error: any) => {
        console.log(error);
      }
    );
    this.propertyService.getActiveEnvironment().subscribe( 
      (result: TransactionResult<IEnvironments[]>) => {
        if (result.success) {
          this.environment = result.data[0].environment;
        }
      },
      (error: any) => {
        console.log(error);
      }
    );
  }
}
