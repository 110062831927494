import { MenuIntemInterface } from './interfaces/menu-item.interface';
import { MenuCollapseInterface } from './interfaces/menu-collapse.interface';
import { MenuGroupInterface } from './interfaces/menu-group.interface';

export class AppNavigationModel {
  public model: any;

  constructor() {
    this.model = [
      {
        'id': 'applications',
        'title': 'Applications',
        'translate': 'NAV.overtime_request',
        'type': 'group',
        'icon': 'apps',
        'children': [
          {
            'id': 'home',
            'title': 'Home',
            'translate': 'NAV.home', 
            'type': 'item',
            'url': '/main/home',
            'icon': 'home'
          },
          {
            'id': 'my-requests',
            'title': 'my-requests',
            'translate': 'NAV.my_requests', 
            'type': 'item',
            'url': '/main/my-requests',
            'icon': 'access_time'
          },
          {
            'id': 'my-approvals',
            'title': 'my-approvals',
            'translate': 'NAV.my_approvals', 
            'type': 'item',
            'url': '/main/my-approvals',
            'icon': 'check_circle_outline'
          },
          {
            'id': 'unblock-rules',
            'title': 'unblock-rules',
            'translate': 'unblock_rules', 
            'type': 'item',
            'url': '/main/unblock-rules',
            'icon': 'no_encryption'
          },
          {
            'id': 'medical-restriction',
            'title': 'medical-restriction',
            'translate': 'NAV.medical_restriction', 
            'type': 'item',
            'url': '/main/medical-restriction',
            'icon': 'medical_services'
          },
          {
            'id': 'catalogs',
            'title': 'catalogs',
            'translate': 'NAV.catalogs', 
            'type': 'collapse',
            'url': '/main/catalogs',
            'icon': 'workspaces_filled',
            'children': [
              {
                'id': 'cost-centers',
                'title': 'cost-centers',
                'translate': 'NAV.cost_centers', 
                'type': 'item',
                'url': '/main/cost-centers',
                'icon': 'closed_caption_off' //attach_money
              },
              {
                'id': 'shifts',
                'title': 'shifts',
                'translate': 'NAV.shifts', 
                'type': 'item',
                'url': '/main/shifts',
                'icon': 'event'
              },
              {
                'id': 'ot-categories',
                'title': 'ot-categories',
                'translate': 'NAV.ot_categories', 
                'type': 'item',
                'url': '/main/ot-categories',
                'icon': 'list_alt'
              },
              {
                'id': 'ot-reasons',
                'title': 'ot-reasons',
                'translate': 'NAV.ot_reasons', 
                'type': 'item',
                'url': '/main/ot-reasons',
                'icon': 'event_available'
              },
              {
                'id': 'medical-restriction-catalog',
                'title': 'medical-restriction-catalog',
                'translate': 'NAV.medical_restriction_catalog', 
                'type': 'item',
                'url': '/main/medical-restriction-catalog',
                'icon': 'add_comment'
              },
              {
                'id': 'positions',
                'title': 'positions',
                'translate': 'NAV.positions', 
                'type': 'item',
                'url': '/main/positions',
                'icon': 'business_center'
              },
            ]
          },
          {
            'id': 'users-roles',
            'title': 'users-roles',
            'translate': 'NAV.users_roles', 
            'type': 'collapse',
            'url': '/main/users-roles',
            'icon': 'people_alt',
            'children': [
              {
                'id': 'employees',
                'title': 'employees',
                'translate': 'NAV.users', 
                'type': 'item',
                'url': '/main/employees',
                'icon': 'people_outline' 
              },
              {
                'id': 'roles',
                'title': 'shifrolests',
                'translate': 'NAV.roles', 
                'type': 'item',
                'url': '/main/roles',
                'icon': 'switch_account'
              }
            ]
          },
          {
            'id': 'sites-buildings',
            'title': 'Sites & Buildings',
            'translate': 'NAV.sites_buildings', 
            'type': 'collapse',
            'icon': 'apartment',
            'children': [
              {
                'id': 'sites',
                'title': 'Sites',
                'translate': 'NAV.sites', 
                'type': 'item',
                'url': '/main/sites',
                'icon': 'business'
              },
              {
                'id': 'buildings',
                'title': 'Buildings',
                'translate': 'NAV.buildings',
                'type': 'item',
                'url': '/main/buildings',
                'icon': 'home_filled'
              }
            ]
          },
          {
            'id': 'configuration',
            'title': 'configuration',
            'translate': 'MYREQUESTS.configuration', 
            'type': 'collapse',
            'icon': 'settings',
            'children': [
              {
                'id': 'rules-configuration',
                'title': 'rules-configuration',
                'translate': 'RULES.rules_configuration', 
                'type': 'item',
                'url': '/main/rules-configuration',
                'icon': 'library_books'
              },
              {
                'id': 'site-configuration',
                'title': 'site-configuration',
                'translate': 'NAV.site_configuration', 
                'type': 'item',
                'url': '/main/site-configuration',
                'icon': 'verified_user'
              },
              {
                'id': 'distribution-list',
                'title': 'distribution-list',
                'translate': 'NAV.distribution_list', 
                'type': 'item',
                'url': '/main/distribution-list',
                'icon': 'send'
              },
              {
                'id': 'db-connections',
                'title': 'db-connections',
                'translate': 'NAV.db_connections', 
                'type': 'item',
                'url': '/main/db-connections',
                'icon': 'storage'
              },
              {
                'id': 'kronos-logs',
                'title': 'kronos-logs',
                'translate': 'NAV.kronos_logs', 
                'type': 'item',
                'url': '/main/kronos-logs',
                'icon': 'library_books'
              }
            ]
          },
          {
            'id': 'reports',
            'title': 'Reports',
            'translate': 'NAV.reports', 
            'type': 'item',
            'url': '/main/reports',
            'icon': 'donut_small'
          }
        ]
      }
    ];
  }
}

