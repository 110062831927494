import { Component, OnInit, Output, EventEmitter,Input } from '@angular/core';

@Component({
  selector: 'button-open',
  templateUrl: './button-open.component.html',
  styleUrls: ['./button-open.component.scss']
})
export class ButtonOpenComponent implements OnInit {
  @Output() eventClick = new EventEmitter<any>();
  @Input() class: string = "btn btn-secondary";

  constructor() { 
    //This is intentional.
  }

  ngOnInit(): void {
    //This is intentional.
  }

  open() {
    this.eventClick.emit();
  }
}
