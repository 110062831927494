import { Injectable } from '@angular/core';
import { RestCallService } from '../../../../core/services/rest-call/rest-call.service';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { NotificationService } from 'src/app/core/services/notification/notification.service';
import { IRoles } from '../Interfaces/IRoles';
import { IScreens } from '../Interfaces/IScreens';
import { TranslateApplicationService } from '../../../../core/translate/translate-application-service';
import { EmployeesService } from '../services/employees-services';
import { TransactionResult } from '../../../../core/services/interfaces/transaction-result';
import { IRolesScreens } from '../Interfaces/IRolesScreens';
import { CognitoService } from 'src/app/core/services/cognito/cognito.service';

@Injectable({
	providedIn: 'root',
})
export class RolesService extends RestCallService {
	public dataToUpdate: IRoles = {
		pkrole: 0,
		fksite: 0,
		rolename: '',
		creationdate: new Date(),
		strcreationdate: '',
		fkemployeecreator: 1,
		lastupdated: new Date(),
		strlastupdated: '',
		fkemployeeupdater: 1,
		active: false,
		employeecreatorname: '',
		employeeupdatername: '',
		username: '',
		recordstatusname: '',
	};
	public rolesScreens: IRolesScreens = {
		fkrole: 0,
		fkscreen: 0,
		fksite: 0,
		username: '',
		screeninangular: '',
	};
	i: number;
	public role: IRoles = {
		pkrole: 0,
		fksite: 0,
		rolename: '',
		creationdate: new Date(),
		strcreationdate: '',
		fkemployeecreator: 1,
		lastupdated: new Date(),
		strlastupdated: '',
		fkemployeeupdater: 1,
		active: false,
		employeecreatorname: '',
		employeeupdatername: '',
		username: '',
		recordstatusname: '',
	};

	resultCreate: any;

	constructor(
		public httpClient: HttpClient,
		private cognito: CognitoService,
		private EmployeesService: EmployeesService,
		public toastr: ToastrService,
		public notificationService: NotificationService,
		public translate: TranslateApplicationService,
	) {
		super(httpClient, 'OTRapi', toastr, notificationService, translate);
	}

	public getList(fksite:number) {
		if (!this.cognito.IsAuhtenticated())
			this.cognito.logOut();
		this.dataToUpdate.fksite = fksite;
		return this.post<IRoles[]>('/api/Roles/GetRolesBySite', this.dataToUpdate);
	}

	public getRoleByName(role: string) {
		if (!this.cognito.IsAuhtenticated())
			this.cognito.logOut();
		this.dataToUpdate.rolename = role;
		this.dataToUpdate.fksite = Number(localStorage.getItem('fksite'));
		return this.post<IRoles[]>('/api/Roles/GetRoleByName',this.dataToUpdate);
	}

	public getScreens(data: any) {
		if (!this.cognito.IsAuhtenticated())
			this.cognito.logOut();
		this.dataToUpdate.pkrole = data.pkrole;
		return this.post<IScreens[]>('/api/Roles/GetScreens',this.dataToUpdate);
	}

	public getScreensByUsername() {
		if (!this.cognito.IsAuhtenticated())
			this.cognito.logOut();
		this.rolesScreens.username = '';
		if (localStorage.getItem('username'))
			this.dataToUpdate.username = localStorage.getItem('username');
		this.dataToUpdate.fksite = Number(localStorage.getItem('fksite'));
		return this.post<IScreens[]>(
			'/api/Roles/GetScreensByUsername',
			this.dataToUpdate,
		);
	}

	public employeeHasPermissionToScreen(screenInAngular: string) {
		if (!this.cognito.IsAuhtenticated())
			this.cognito.logOut();
		this.rolesScreens.screeninangular = screenInAngular;
		this.rolesScreens.username = '';
		if (localStorage.getItem('username'))
			this.rolesScreens.username = localStorage.getItem('username');
		this.rolesScreens.fksite = Number(localStorage.getItem('fksite'));
		return this.post<IScreens[]>('/api/Roles/EmployeeHasPermissionToScreen',this.rolesScreens);
	}

	public async createRole(data: any, dataScreens: any) {
		if (!this.cognito.IsAuhtenticated())
			this.cognito.logOut();
		this.i = 0;
		this.dataToUpdate.rolename = data.rolename;
		this.dataToUpdate.fksite = Number(localStorage.getItem('fksite'));
		this.dataToUpdate.username = localStorage.getItem('username');
		var response = await this.post('/api/Roles/CreateRole',this.dataToUpdate).toPromise();
		if (response.success) {
			var response1 = await this.post<IRoles>('/api/Roles/GetRoleByName',this.dataToUpdate,).toPromise();
			if (response1.success) {
				this.role.pkrole = response1.data[0].pkrole;
				this.addRolesScreens(data, dataScreens);
			}
			else
				return this.get<IRoles>('/api/Roles/GetFail');
		}
		else
			return this.get<IRoles>('/api/Roles/GetFail');
		return this.post<IRoles>('/api/Roles/GetRoleByName', this.dataToUpdate);
	}

	public async updateRole(data: any, dataScreens: any) {
		if (!this.cognito.IsAuhtenticated())
			this.cognito.logOut();
		this.dataToUpdate.pkrole = data.pkrole;
		this.dataToUpdate.rolename = data.rolename;
		this.dataToUpdate.fksite = Number(localStorage.getItem('fksite'));
		this.dataToUpdate.username = localStorage.getItem('username');
		this.rolesScreens.fkrole = data.pkrole;
		this.rolesScreens.fksite = Number(localStorage.getItem('fksite'));
		this.role.pkrole = data.pkrole;
		var response1 = await this.put('/api/Roles/UpdateRole',this.dataToUpdate,).toPromise();
		if (!response1.success) {
			return this.get<IRoles>('/api/Roles/GetFail');
		}
		else{
			var response = await this.post('/api/Roles/DisableRolesScreens',this.rolesScreens,).toPromise();
			if (response.success) {
				this.addRolesScreens(data, dataScreens);
			}
		}
		return this.post<IRoles>('/api/Roles/GetRoleByName', this.dataToUpdate);
	}

	public async addRolesScreens(data: any, dataScreens: any) {
		if (!this.cognito.IsAuhtenticated())
			this.cognito.logOut();
		this.i = 0;
		for (const field in data.orders) {
			if (data.orders[this.i]) {
				this.rolesScreens.fkscreen = dataScreens[this.i].pkscreen;
				this.rolesScreens.fkrole = this.role.pkrole;
				this.rolesScreens.fksite = Number(localStorage.getItem('fksite'));
				this.rolesScreens.username = localStorage.getItem('username');
				var response = await this.post('/api/Roles/CreateRolesScreens',this.rolesScreens).toPromise();
				if (response) {
					console.log(response);
				}
			}
			this.i = this.i + 1;
		}
	}

	public disableRole(data: any) {
		if (!this.cognito.IsAuhtenticated())
			this.cognito.logOut();
		this.dataToUpdate.pkrole = data.pkrole;
		this.dataToUpdate.username = localStorage.getItem('username');
		return this.put('/api/Roles/DisableRole', this.dataToUpdate);
	}

	public availableRole(data: any) {
		if (!this.cognito.IsAuhtenticated())
			this.cognito.logOut();
		this.dataToUpdate.pkrole = data.pkrole;
		this.dataToUpdate.username = localStorage.getItem('username');
		return this.put('/api/Roles/AvailableRole', this.dataToUpdate);
	}
}
