import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'button-approve',
  templateUrl: './button-approve.component.html',
  styleUrls: ['./button-approve.component.scss']
})
export class ButtonApproveComponent implements OnInit {
  @Output() eventClick = new EventEmitter<any>();

  constructor() { 
    //This is intentional.
  }

  ngOnInit(): void {
    //This is intentional.
  }

  approve() {
    this.eventClick.emit();
  }

}
